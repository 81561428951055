<template>
  <div class="pa-4">
    <v-card class="mt-2 py-2">
      <v-form ref="orderform" id="orderform" v-model="valid" lazy-validation>
      <v-toolbar flat>
          <v-toolbar-title>{{$route.name}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                 -->
          <v-spacer></v-spacer>
          <v-btn color="grey" dark class="mb-2 mr-2" @click="$router.go(-1)" small>
              <v-icon small>mdi-arrow-left</v-icon>
              Back
          </v-btn>

      </v-toolbar>    
      <div class="mt-3">
          <v-row class="mx-5 py-0" align="center">
            <v-col cols="2">Group</v-col>
            <v-col cols="4"><v-select :items="groups" item-text="description" return-object v-model="selectedGroup" outlined dense hide-details="auto" class="compact-form" :disabled="order.status==1" @change="changeCategory" :rules="reqRule"></v-select></v-col>
            <v-col cols="2">Remarks</v-col>
            <v-col cols="4"><v-text-field v-model="order.remarks" outlined dense hide-details="auto" class="compact-form" :disabled="order.status==1"></v-text-field></v-col>
          </v-row>
          <v-row class="mx-5" align="center">
            <v-col cols="2">Hari Kirim</v-col>
            <v-col cols="4">
              <v-menu v-model="dateDelivMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :disabled="!this.selectedGroup || order.status==1">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="order.delivery_date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined class="compact-form" :rules="reqRule"></v-text-field>
                </template>
                <v-date-picker v-model="order.delivery_date" @change="setDateExpired" :min="today"></v-date-picker>
              </v-menu>

            </v-col>
          </v-row>
          <v-row class="mx-5" align="center">
            <template v-if="showExpiredDate">
            <v-col cols="2">Tanggal Expired</v-col>
            <v-col cols="4">
              <v-menu v-model="dateExpMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" disabled>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="order.expired_date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined class="compact-form" disabled :rules="reqRule"></v-text-field>
                </template>
                <v-date-picker v-model="order.expired_date"></v-date-picker>
              </v-menu>

            </v-col>
            </template>
            <v-spacer></v-spacer>
            <v-col cols="4">
              <v-file-input outlined dense label="Upload CSV" @change="onFileChange" prepend-icon="mdi-upload" class="compact-form" hide-details="auto" v-if="order.status==0" :disabled="order.delivery_date===null"></v-file-input>
            </v-col>
          </v-row>
          
          <!-- <v-row class="mx-5">
            <v-spacer></v-spacer>
            <v-flex md4 class="">
            </v-flex>
                
          </v-row> -->
        <v-card outlined class="ma-3 mt-5" width="800px">
          <v-simple-table fixed-header height="350px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Code</th>
                  <th class="text-left">Volume</th>
                  <th class="text-left">Variant</th>
                  <th class="text-left">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, vidx) in order.order_details" :key="vidx">
                  <td>{{item.variant.code}}</td>
                  <td>{{item.variant.size}} ml</td>
                  <td>{{item.variant.name}}</td>
                  <!-- <td>{{item.QTY}}</td> -->
                  <td>
                    <v-text-field type="number" dense outlined v-model="item.qty_ordered" hide-details class="compact-form centered-input" style="width:100px" :rules="reqRule" :disabled="order.status==1 && profile.role_code!='SUP'" @wheel="$event.target.blur()"></v-text-field>
                  </td>
                </tr>

              </tbody>
            </template>
          </v-simple-table>  
        </v-card>         
      </div>    
      <v-card-actions class="pr-4">
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" @click="saveOrder" v-if="order.status==0 || profile.role_code=='SUP'">Save</v-btn>
      </v-card-actions> 
      </v-form>
    </v-card>
    <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
    <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
    <v-overlay :value="onProgress" z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>        

  </div>
</template>

<script>
// import RequestProd from '../components/RequestProd.vue'
// import { VueCsvImport } from 'vue-csv-import';
import moment from 'moment'

export default {
  // components: { RequestProd },
  data() {
    return {
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true,
      saved: false,
      saveError: false,
      groups: null,
      selectedGroup: null,
      order: {
        order_batch: "",
        order_id: "",
        production_date: null,
        delivery_date: null,
        expired_date: null,
        remarks: "",
        status: 0,
        order_category: "",
        order_details:[]
      },
      dateProdMenu: false,
      dateDelivMenu: false,
      dateExpMenu: false,
      variants: null,
      onProgress: false,
      showExpiredDate: true
    }
  },
  computed: {
    today(){
      return moment().format("YYYY-MM-DD");
    },
    profile () {
      return this.$store.state.profile
    },
  },
  filters: {
      sdate: function (date) {
          return moment(date).format('ddd D/MM');
      }
  },
  async created() {
    this.order.order_batch = this.$route.params.batch
    this.groups = (await this.$store.dispatch('ordercategory/getObjs')).data.data
    this.variants = (await this.$store.dispatch('variant/getObjs')).data.data
    const order_id = this.$route.params.order_id
    if (order_id!='new') {
      this.loadOrder(order_id)
    }
  },
  methods: {
    changeCategory(ev) {
      this.showExpiredDate = ev.plus_expired
    },
    async loadOrder(order_id) {
      const order = (await this.$store.dispatch('getObjs', {apidomain:'orders?fields[order_id]=', parameter:order_id})).data.data[0]
      order.order_details = order.details
      delete order.details
      this.order = JSON.parse(JSON.stringify(order))
      this.selectedGroup = this.groups.find(el => this.order.order_category == el.code)
      this.showExpiredDate = this.selectedGroup.plus_expired
      // this.$set(this.order, 'order_details', this.order.details)
      this.order.order_details.forEach(element => {
        var variant = this.variants.find(el => el.code === element.variant_code)
        element.variant = variant
        element.QTY = element.qty_ordered
        this.cleanObj(element)
      });
      console.log(this.order)
      this.order.order_details.sort((a,b) => parseFloat(a.variant.size) - parseFloat(b.variant.size))

    },
    async generateOrderID() {
      // Generate Order ID
      let url = this.$store.state.config.apiHost + "others/getordercount"
      let lastId = (await this.$axios.get(url, {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})).data.total
      console.log(lastId)
      let order_id = (lastId==null)? 10001 : Number(lastId) + 1
      console.log(order_id)
      return order_id
    },
    async saveOrder() {
      await this.$refs.orderform.validate();
      if (!this.order.order_details.length) {
        this.valid = false
        this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Order line tidak boleh kosong!"})        
      }
      if (!this.valid) return;

      this.onProgress = true
      const order_id = this.$route.params.order_id
      if (order_id!='new') {
        await this.$store.dispatch('order/deleteObj', order_id)
      }
      this.order.order_category = this.selectedGroup.code
      this.order.order_id = await this.generateOrderID()
      console.log({orders:[this.order]})
      const docs = await this.$store.dispatch('order/postObj', {orders:[this.order]})
      
      if (docs.status==200 || docs.status==201) {
        this.$router.go(-1)
        this.saved = true
      }
      else this.saveError = true

      this.onProgress = false
    },
    setDates() {
      this.order.delivery_date = moment(this.order.production_date).add(this.selectedGroup.plus_delivery, 'd').format("YYYY-MM-DD")
      this.order.expired_date = moment(this.order.delivery_date).add(this.selectedGroup.plus_expired, 'd').format("YYYY-MM-DD")
    },
    setDateExpired() {
      this.order.expired_date = moment(this.order.delivery_date).add(this.selectedGroup.plus_expired, 'd').format("YYYY-MM-DD")
    },
    onClear() {
      this.$set(this.order, 'order_details', [])
      console.log(this.order)
      // this.order.order_details = []
    },
    async onFileChange(e) {
      if (e==null) { // on clear text
        console.log('cleared')
        return this.onClear()
      }
      if (e.name.substring(0, 10)!=this.order.delivery_date) {
        return this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Nama file tidak sesuai dengan Hari Kirim!"})
      }
      this.onClear();
      let input = await this.getCsvFile(e)
      let importedItems = []
      this.order.order_details = []
      const importedItemsTemp = this.csvToJson(input)
      importedItems = importedItemsTemp.filter(el => {
        return (el.KODE_PRODUK !== null && el.QTY != 0)
      })
      importedItems.forEach(element => {
        console.log(element)
        var variant = this.variants.find(el => el.code === element.KODE_PRODUK)
        element.variant = variant
        this.order.order_details.push({
          variant_code: element.KODE_PRODUK,
          type: 'ALL',
          qty_ordered: element.QTY,
          variant: variant
        })
      });
      console.log(this.order)
      importedItems.sort((a,b) => parseFloat(a.variant.size) - parseFloat(b.variant.size))
    },
    getCsvFile(file) {
      return new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.readAsText(file);
          reader.onload = function () {
              resolve(reader.result);
          };
          reader.onerror = function (error) {
              reject('Error: ', error);
          };
      })      
    },
    csvToJson (text, headers, quoteChar = '"', delimiter = ',') {
      const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

      const match = line => [...line.matchAll(regex)]
        .map(m => m[2])  // we only want the second capture group
        .slice(0, -1);   // cut off blank match at the end

      const lines = text.split('\n');
      const heads = headers ?? match(lines.shift());
      console.log(lines)

      let filteredLines = lines.filter(function (el) {
        return el != '';
      });

      console.log(filteredLines)

      return filteredLines.map(line => {
          return match(line).reduce((acc, cur, i) => {
            // Attempt to parse as a number; replace blank matches with `null`
            const val = cur.length <= 0 ? null : Number(cur) || cur;
            const key = heads[i] ?? `extra_${i}`;
            return { ...acc, [key]: val };
          }, {});
      });
    }
  }
}
</script>
<style>
  #orderform .col {
    padding: 5px!important;
  }
</style>